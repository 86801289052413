<template>
  <div class="login">
    <header>
      <div class="left">
        <img v-lazy="logoImg" class="logo-img" />
        <span>FILCOIN FUND ALLIANCE</span>
      </div>

      <img class="lang-img" src="../../assets/images/cn.png" />
    </header>
    <img src="../../assets/images/logo-title.png" alt="" class="title" />
    <button @click="handleLogin" class="login-btn">
      {{ isRegister ? "登录" : "注册" }}
    </button>
  </div>
</template>
<script>
import { mapMutations } from "vuex";
import { logoImg, testAddress } from "../../config/constants";
import { getWalletAddress, isWhichPlat } from "../../utils/wallet";
import { checkRegister } from "../../server/index";

export default {
  name: "Login",
  data() {
    return {
      logoImg: logoImg,
      isRegister: false,
    };
  },
  methods: {
    ...mapMutations(["SET_ADDRESS"]),
    async handleLogin() {
      this.isRegister
        ? this.$router.push("/home")
        : this.$router.push(`/register?code=${this.$route.query.code}`);
    },
  },
  async created() {
    if (isWhichPlat()) {
      const addressResult = await getWalletAddress();
      if (addressResult) {
        this.SET_ADDRESS(addressResult);
        this.isRegister = await checkRegister(addressResult);
      } else {
        this.$toast("请选择钱包");
      }
    } else {
      if (process.env.NODE_ENV === "development") {
        this.SET_ADDRESS(testAddress);
        this.isRegister = await checkRegister(testAddress);
      }
      if (process.env.NODE_ENV === "production") {
        this.$toast("请在ImToken钱包中打开");
      }
    }
  },
};
</script>

<style lang="less" scoped>
.login {
  width: 100%;
  height: 100%;
  background: #0f151f;
  opacity: 1;
  background-image: url("../../assets/images/login-bg.png");
  background-size: cover;
  box-sizing: border-box;
  padding-top: 15px;
  position: relative;

  header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    padding-left: 15px;
    padding-right: 15px;

    .left {
      display: flex;
      align-items: center;

      .logo-img {
        width: 28px;
        height: 28px;
        margin-right: 5px;
      }

      span {
        font-size: 16px;
        font-family: "YouSheBiaoTiHei";
        font-weight: bold;
        color: #019969;
      }
    }

    .lang-img {
      width: 22px;
      height: 18px;
    }
  }

  .title {
    position: absolute;
    top: 168px;
    left: 50%;
    transform: translatex(-50%);
    width: 159px;
    height: 87px;
  }

  .login-btn {
    position: absolute;
    top: 500px;
    left: 50%;
    transform: translateX(-50%);
    width: 179px;
    height: 41px;
    background: linear-gradient(90deg, #05c68b 0%, #019d6b 100%);
    box-shadow: 0px 3px 6px rgba(1, 153, 105, 0.25);
    opacity: 1;
    border-radius: 21px;
    font-size: 16px;
    font-family: "PingFang SC";
    font-weight: bold;
    line-height: 41px;
    text-align: center;
    color: #ffffff;
    outline: none;
    border: none;
  }
}
</style>
